<template>
  <div class="login-wrapper">
    <div class="login-logo">
      <div class="logo">
        <img
          class="auto-image"
          src="../assets/images/parking_logo.png"
          alt=""
        />
      </div>
      <div class="logo-txt">
        运营数据统计分析系统
      </div>
    </div>
    <div class="login-input-box">
      <div class="input-box">
        <div class="input-username login-input">
          <input
            v-model="username"
            @input="limitInput('username')"
            maxlength="16"
            type="text"
            placeholder="请输入用户名"
          />
        </div>
        <div class="input-password login-input">
          <input
            v-model="password"
            @input="limitInput('password')"
            maxlength="16"
            type="text"
            placeholder="请输入密码"
            v-if="showPassword"
          />
          <input
            v-model="password"
            @input="limitInput('password')"
            maxlength="16"
            type="password"
            placeholder="请输入密码"
            v-else
          />
          <div
            class="password-eye"
            @touchstart="chooseShowPassword(true)"
            @touchend="chooseShowPassword(false)"
          >
            <span class="icon-eye"></span>
          </div>
        </div>
      </div>
      <div class="input-btn" @click="onLogin">确定</div>
    </div>
    <div class="login-footer">
      <div class="footer-logo">
        <img class="auto-image" src="../assets/images/ic_company.png" alt="" />
      </div>
      <div class="footer-txt">
        新中望信息科技有限公司
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { mapMutations } from "vuex";

export default {
  name: "Login",

  data: () => ({
    showPassword: false,
    username: "",
    password: ""
  }),

  methods: {
    ...mapMutations({
      setAccessTokenExipreTime: "account/setAccessTokenExipreTime",
      setRefreshTokenExipreTime: "account/setRefreshTokenExipreTime",
      setAccessToken: "account/setAccessToken",
      setRefreshToken: "account/setRefreshToken",
      setUserName: "account/setUserName",
      setRoleName: "account/setRoleName",
      setAvatar: "account/setAvatar"
    }),

    // 限制输入框输入
    limitInput(propName) {
      this[propName] = this[propName].replace(/[^A-Za-z0-9_\-~!@.*+]/g, "");
    },

    // 长按显示密码
    chooseShowPassword(val) {
      this.showPassword = val;
    },

    saveAccountData(data) {
      this.setAccessToken(data["accessToken"]);
      this.setRefreshToken(data["refreshToken"]);
      this.setAccessTokenExipreTime(data["accessTokenExipreTime"]);
      this.setRefreshTokenExipreTime(data["refreshTokenExipreTime"]);
      this.setUserName(data["user"]["userName"]);
      this.setRoleName(data["user"]["roleName"]);
      this.setAvatar(data["user"]["avatar"]);
    },

    async onLogin() {
      if (this.username.length < 1 || !this.username) {
        Dialog.alert({
          title: "温馨提示",
          message: "请先输入用户名"
        });
      } else if (this.password.length < 1 || !this.password) {
        Dialog.alert({
          title: "温馨提示",
          message: "请先输入密码"
        });
      } else {
        let { data } = await this.$api.login(this.username, this.password);
        this.saveAccountData(data["data"]);
        console.log("输入正确 == >", data["data"]);

        this.$router.replace({ name: "Index" });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.login-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.logo {
  width: 200px;
  margin: 80px auto 14px;
}

.logo-txt {
  color: #003399;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
}

.login-input-box {
  flex: 1;
  padding: 0 30px;
}

.input-box {
  padding: 0 18px;
  margin-bottom: 18px;
  border-radius: 8px;
  background-color: #edf0f7;

  .login-input {
    display: flex;
    align-items: center;
    height: 54px;
    padding-top: 10px;

    &.input-username {
      border-bottom: 1px solid rgba(7, 17, 27, 0.1);
    }

    &.input-password {
      position: relative;
    }

    .password-eye {
      display: flex;
      align-items: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 10px;

      span {
        font-size: 20px;
      }
    }
  }
}

.input-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background-color: #4b90f3;
  border-radius: 4px;
  color: #fff;
}

.login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;

  .footer-logo {
    width: 36px;
    margin: 0 auto 6px;
  }

  .footer-txt {
    color: #6c7079;
    font-weight: bold;
  }
}
</style>
